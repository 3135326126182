import { Container, InternalLink, NavigationPageSpacer } from "components";
import { Box, Heading, Text } from "@chakra-ui/layout";
import { route } from "config/routes";

export default function FourOhFour() {
  return (
    <>
      <NavigationPageSpacer />

      <Container>
        <Box
          minH={600}
          py={12}
          textAlign="center"
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Heading mb={5}>Page not found</Heading>
          <Text fontSize="lg">
            If you think this is an error, please{" "}
            <InternalLink href={route("CONTACT_INDEX")}>
              <Box
                as="span"
                color="primary.600"
                _hover={{
                  textDecoration: "underline",
                }}
              >
                get in touch
              </Box>
            </InternalLink>
            .
          </Text>
        </Box>
      </Container>
    </>
  );
}
